import { useMsal } from "@azure/msal-react";
import React, { useState } from "react";
import { authFetch, BASE_URL } from "./authFetch.js";
import "./styles/SubscriptionDetails.css";

const SubscriptionStatus = {
    AwaitingActivationRequest: "AwaitingActivationRequest", //PendingFulfillmentStart
    AwaitingActivation: "AwaitingActivation", //PendingFulfillmentStart
    ActivationInProgress: "ActivationInProgress", //PendingFulfillmentStart
    Activated: "Activated", //Subscribed
    Suspended: "Suspended", //Suspended
    DeactivationRequest: "DeactivationRequest", //User requesting deactivation on our side
    Deactivated: "Deactivated", //Unsubscribed
};

const subscriptionStatusLabel = (status) => {
    switch (status) {
        case SubscriptionStatus.AwaitingActivationRequest:
            return "Confirmation required";
        case SubscriptionStatus.AwaitingActivation:
            return "Awaiting activation by Mapiq";
        case SubscriptionStatus.ActivationInProgress:
            return "Mapiq activation in progress";
        case SubscriptionStatus.Activated:
            return "Active";
        case SubscriptionStatus.Deactivated:
        case SubscriptionStatus.Suspended:
            return "Deactivated";
        case SubscriptionStatus.DeactivationRequest:
            return "Deactivation is in progress";
        default:
            return "Unknown";
    }
};

const putSubscriptionState = (msal, subscriptionId, state) => {
    return authFetch(msal, `${BASE_URL}/subscriptions/${subscriptionId}/status`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(state),
    });
};

export function SubscriptionDetails({ subscription }) {
    const msal = useMsal();
    const [errorMessage, setErrorMessage] = useState("");
    const [subscriptionStatus, setSubscriptionStatus] = useState(
        subscription.subscriptionStatus
    );
    const [updatingState, setUpdatingState] = useState(false);

    const buttonLabel =
        subscriptionStatus === SubscriptionStatus.AwaitingActivationRequest
            ? "Request activation"
            : subscriptionStatus === SubscriptionStatus.AwaitingActivation
                ? "Activation requested"
                : subscriptionStatus === SubscriptionStatus.ActivationInProgress
                    ? "Activation in progress"
                    : subscriptionStatus === SubscriptionStatus.AwaitingActivationRequest
                        ? "Request activation"
                        : subscriptionStatus === SubscriptionStatus.Activated
                            ? "Deactivate"
                            : "";

    const buttonDisabled =
        updatingState ||
        (subscriptionStatus !== SubscriptionStatus.AwaitingActivationRequest &&
            subscriptionStatus !== SubscriptionStatus.Activated);

    const requestActivation = () => {
        setUpdatingState(true);
        setErrorMessage("");
        putSubscriptionState(
            msal,
            subscription.subscriptionId,
            SubscriptionStatus.AwaitingActivation
        )
            .then(([err, resp]) => {
                if (err) {
                    console.error(err);
                    setErrorMessage("Something went wrong, please try again.");
                } else {
                    setSubscriptionStatus(SubscriptionStatus.AwaitingActivation);
                }
            })
            .finally(() => {
                setUpdatingState(false);
            });
    };

    const cancelSubscription = () => {
        setUpdatingState(true);
        setErrorMessage("");

        if (!confirm("Proceeding will cancel your Mapiq subscription.")) {
            setUpdatingState(false);
            return;
        }

        putSubscriptionState(
            msal,
            subscription.subscriptionId,
            SubscriptionStatus.DeactivationRequest
        )
            .then(([err, resp]) => {
                if (err) {
                    console.error(err);
                    setErrorMessage("Something went wrong, please try again");
                } else {
                    setSubscriptionStatus(SubscriptionStatus.DeactivationRequest);
                }
            })
            .finally(() => {
                setUpdatingState(false);
            });
    };

    const buttonAction =
        subscriptionStatus === SubscriptionStatus.AwaitingActivationRequest
            ? requestActivation
            : subscriptionStatus === SubscriptionStatus.Activated
                ? cancelSubscription
                : () => { console.log("no action") };

    return (
        <>
            {subscriptionStatus === SubscriptionStatus.AwaitingActivationRequest &&
                <p>
                    Please verify the following information is correct before submitting
                    your subscription for activation.
                </p>
            }

            {subscriptionStatus === SubscriptionStatus.AwaitingActivation &&
                <p>
                    We’ve successfully received your purchase request.
                </p>
            }

            {subscriptionStatus === SubscriptionStatus.ActivationInProgress &&
                <p>
                    We’re setting things up for you!
                </p>
            }

            {subscriptionStatus === SubscriptionStatus.Activated &&
                <p>
                    Your Mapiq subscription is active!
                </p>
            }

            <div className="SubscriptionDetails-card">
                <h4 className="SubscriptionDetails-cardheader">Subscription details</h4>
                <div className="SubscriptionDetails-cardbody">
                    <table className="SubscriptionDetails-table">
                        <tbody>
                            <tr>
                                <td>Current user</td>
                                <td>{subscription.userDisplayName}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>{subscription.userEmail}</td>
                            </tr>
                            <tr>
                                <td>Subscription name</td>
                                <td>{subscription.subscriptionName}</td>
                            </tr>
                            <tr>
                                <td>Plan name</td>
                                <td>{subscription.planName}</td>
                            </tr>
                            <tr>
                                <td>Tenant identifier</td>
                                <td>
                                    <code>{subscription.tenantId}</code>
                                </td>
                            </tr>
                            <tr>
                                <td>Subscription state</td>
                                <td>
                                    {subscriptionStatusLabel(subscription.subscriptionStatus)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {buttonLabel &&
                <button
                    className={`SusbcriptionDetails-cta ${subscriptionStatus === SubscriptionStatus.Activated ? "Button--red" : ""}`}
                    disabled={buttonDisabled}
                    onClick={buttonAction}
                >
                    {buttonLabel}
                </button>
            }

            {errorMessage && <span className="SubscriptionDetails-error">{errorMessage}</span>}
        </>
    );
}
