import React from "react";
import "./styles/Spinner.css";

export const Spinner = ({ size }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="180" height="180" fill="#2EC3FF"/>
      <mask id="lineMask">
        <path d="M125 156.6 L80 130 H50 V50 H130 V140" 
              stroke="white" strokeWidth="24"/>
      </mask>  
      <path
            d="M120 140 L80 120 H60 V60 H120 V140 L140 130 V40 H40 V140 H80 L120 160 V140 Z"  
            mask="url(#lineMask)" 
            fill="white"/>
    </svg>
  );
}