import React from "react";
import { createRoot } from "react-dom/client";


import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import "./styles/index.css";

import App from "./App.jsx";
import { msalConfig } from "./authConfig.js";

const msalInstance = new PublicClientApplication(msalConfig);
const container = document.body;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App></App>
    </MsalProvider>
  </React.StrictMode>
);
