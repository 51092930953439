import { loginRequest } from "./authConfig.js";

//export const BASE_URL = "https://localhost:7098/api";
export const BASE_URL = "/api"
export const authFetch = async (msal, url, cfg = {}) => {
  let accessToken = null;
  
  try {
    const tokenResponse = await msal
      .instance
      .acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: msal.accounts[0]
      });
    accessToken = tokenResponse.accessToken;
  } catch(err) {
    
    return [err, null];
  }

  return fetch(url, {
    ...cfg,
    headers: {
      ...(cfg.headers ?? {}),
      "Authorization": `Bearer ${accessToken}`
    }
  })
    .then(async r => {
      if (r.ok) return [ null, await r.json() ];
  
      return [`${r.status}: ${r.statusText}`, null];
    })
    .catch(err => {
      return [ err, null ];
    });
}