import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useIsAuthenticated,
    useMsal,
} from "@azure/msal-react";
import React, { useState, useEffect } from "react";
import { loginRequest } from "./authConfig.js";
import "./styles/App.css";
import Main from "./Main.jsx";
import { Spinner } from "./Spinner.jsx";
import { InteractionStatus } from "@azure/msal-browser";

export default function App() {
    // State
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState("");
    const msal = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const { instance, inProgress } = msal;

    msal.instance.handleRedirectPromise().then(response => {
        if (response.state)
        {
            setToken(response.state);
        }
    });

    // Helper functions
    const login = (token) => {
        setLoading(true);
        msal.instance.loginRedirect({
            ...loginRequest,
            state: token
        });
    };

    const logout = () => {
        setLoading(true);
        msal.instance.logoutRedirect({ postLogoutRedirectUri: "/logout" });
    };

    const useToken = ()  => {
        return new URLSearchParams(window.location.search).get("token");
    }

    useEffect(function autoLogin() {
        let canceled = false;
        let redirectQueued = false;

        if (inProgress === InteractionStatus.None && !isAuthenticated) {
            redirectQueued = true;

            const token = useToken();

            // Note: inProgress changes to NONE _before_ isAuthenticated changes to TRUE
            // If we don't add a timeout here, it will queue the redirect before we realize
            // it's not needed.
            // There's probably a better way to solve this, but it's not really what this
            // project is about.
            setTimeout(() => {
                if (!canceled) {
                    if (!document.location.pathname.includes("/logout")) login(token);
                    else history.replaceState({}, "", "/");
                }
            }, 100);
        }

        return () => {
            canceled = true;
        }
    }, [inProgress, isAuthenticated]);

    const renderBody = () => {
        if (inProgress !== InteractionStatus.None) {
            return (
                <main className="Main">
                    <div className="Main-centerwrap">
                        <div className="Spinner-wrap">
                            <Spinner size={30} />
                            <span>Verifying sign-in...</span>
                        </div>
                    </div>
                </main>
            );
        }
        if (loading) {
            return (
                <main className="Main">
                    <div className="Main-centerwrap">
                        <div className="Spinner-wrap">
                            <Spinner size={30} />
                            <span>Redirecting...</span>
                        </div>
                    </div>
                </main>
            );
        }

        if (!isAuthenticated) {
            return (
                <main className="Main">
                    <div className="Main-centerwrap">
                        <section>
                            <h1>Welcome to Mapiq!</h1>
                            <p>Please sign in to continue</p>
                            <button
                                className="Button--secondary Button--iconright"
                                onClick={login}
                            >
                                <div className="Button-content">
                                    Sign in
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9 4.5L16.5 12L9 19.5"
                                            stroke="black"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                            </button>
                        </section>
                    </div>
                </main>
            )
        }

        return (            
            <main className="Main">
                <Main token={token}/>
            </main>
        );
    }

    return (
        <>
            <header className="Header">
                <a href="https://www.mapiq.com" className="Header-logo">
                    <svg
                        width="123"
                        viewBox="0 0 205 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M63.8325 15.304V40H60V10H64.2158L74.8989 23.464L85.4862 10H89.702V40H85.8695V15.256L74.851 28.984L63.8325 15.304ZM122.422 40L118.853 31.6H105.151L101.63 40H97.702L110.062 10H114.11L126.494 40H122.422ZM112.026 14.632L106.732 27.52H117.272L112.026 14.632ZM134.494 40H138.314V28.445H144.14C145.31 28.445 146.504 28.3014 147.626 28.0383C149.87 27.5359 151.637 26.2679 152.903 24.3541C153.691 23.134 154.073 21.7464 154.192 20.3349C154.312 18.7081 154.216 17.1053 153.619 15.5502C152.807 13.4211 151.399 11.8182 149.321 10.9091C147.96 10.311 146.504 10 145 10H134.494V40ZM144.856 24.4976H138.314H138.29V13.756C140.2 13.7321 142.827 13.7081 144.737 13.756C146.122 13.7799 147.459 14.1866 148.533 15.0718C149.56 15.933 150.133 17.0574 150.3 18.4211C150.491 19.9282 150.181 21.2679 149.321 22.512C148.82 23.2536 148.08 23.6603 147.316 23.9952C146.552 24.3062 145.716 24.5215 144.856 24.4976ZM166.514 10H162.237V40H166.514V10ZM189.485 10C197.746 10 204.456 16.7233 204.456 25C204.456 28.4312 203.299 31.5842 201.378 34.0881L204.41 37.1252L201.726 39.8145L198.718 36.8006C196.172 38.7944 192.979 40 189.485 40C181.225 40 174.514 33.2767 174.514 25C174.514 16.7233 181.225 10 189.485 10ZM189.485 13.7326C183.284 13.7326 178.24 18.7867 178.24 25C178.24 31.2365 183.261 36.2674 189.485 36.2674C191.938 36.2674 194.205 35.4791 196.057 34.1345L193.187 31.2597L195.871 28.5703L198.718 31.4219C199.99 29.6136 200.731 27.3879 200.731 25C200.731 18.7867 195.686 13.7326 189.485 13.7326Z"
                            fill="black"
                        />
                        <path d="M0 0V50H50V0H0Z" fill="#2EC3FF" />
                        <path
                            d="M34 40L22 34H16V16H34V40L40 37V10H10V40H22L34 46V40Z"
                            fill="white"
                        />
                        <path
                            d="M34 40L22 34H16V16H34V40L40 37V10H10V40H22L34 46V40Z"
                            fill="white"
                        />
                    </svg>
                </a>

                <UnauthenticatedTemplate>
                    <button className="Header-button" onClick={login}>
                        Sign in
                    </button>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    <div className="Header-account">
                        <div className="Header-name">
                            {msal.accounts[0]?.idTokenClaims?.name}
                        </div>
                    </div>
                </AuthenticatedTemplate>
            </header>

            {renderBody()}

            <footer className="Footer">
                <div className="Footer-contact">
                    <h3>Get in touch</h3>
                    <p>
                        <a href="mailto:info@mapiq.com">info@mapiq.com</a>
                    </p>
                    <p>
                        <a href="tel:+31157440130">+31 (0)15 7440130</a>
                    </p>
                    <p>Oostsingel 209, 2612 HL Delft, The Netherlands</p>
                </div>
                <p>© {new Date().getFullYear()} Mapiq</p>
            </footer>
        </>
    );
}
