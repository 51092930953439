import "./styles/Main.css";
import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { SubscriptionDetails } from "./SubscriptionDetails.jsx";
import { authFetch, BASE_URL } from "./authFetch.js";
import { Spinner } from "./Spinner.jsx";

const resolveSubscription = (msal, { token = null, tenantId = null }) => {
    return (
        // Attempt through token
        resolveSubscriptionThroughToken(msal, token)
            .then(
                ([err, resp]) => {
                    // When there's a token error, try to resolve through tenant id as a fallback
                    if (err) return resolveSubscriptionThroughTenant(msal, tenantId);

                    return [err, resp];
                }
            )
    )
}

const resolveSubscriptionThroughToken = (msal, token) => {
    if (!token) return Promise.resolve(["No token", null]);
    console.log("Resolving sub. through query token");
    return authFetch(msal, `${BASE_URL}/subscriptions?token=${encodeURIComponent(token)}`)
};

const resolveSubscriptionThroughTenant = (msal, tenantId) => {
    if (!tenantId) return Promise.resolve(["No tenant id", null]);
    console.log("Resolving sub. through tenant ID from idToken");
    return authFetch(msal, `${BASE_URL}/tenants/${tenantId}/subscription`)
};

const State = {
    Loading: "LOADING",
    NotFound: "NOT_FOUND",
    SubscriptionAvailable: "SUBSCRIPTION_AVAILABLE",
    Error: "ERROR",
};

export default function Main({ token }) {
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const msal = useMsal();

    useEffect(function loadSubscription() {
        const tenantId = msal.accounts[0]?.tenantId ?? null;
        let canceled = false;

        resolveSubscription(msal, { token, tenantId })
            .then(([err, sub]) => {
                if (canceled) return;

                setHasError(err !== null);
                setSubscription(sub);
                setLoading(false);
            })

        return () => {
            canceled = true;
        }
    }, []);

    const state = loading
        ? State.Loading
        : hasError
            ? State.Error
            : subscription
                ? State.SubscriptionAvailable
                : State.NotFound;

    return (
        <>
            <h1>Your Mapiq Purchase</h1>
            {MainBody(state, subscription)}
        </>
    );
}

const LoadingPage = () => {
    return <div className="Spinner-wrap">
        <Spinner size={30} />
        <span>Loading...</span>
    </div>
};

const NotFoundPage = () => {
    return <h2>No subscription found.</h2>;
};

const ErrorPage = () => {
    return <h2>Something's wrong. Please reload the page or get in touch.</h2>;
};

function MainBody(state, subscription) {
    switch (state) {
        case State.Loading:
            return LoadingPage();
        case State.SubscriptionAvailable:
            return <SubscriptionDetails subscription={subscription} />
        case State.NotFound:
            return NotFoundPage();
        case State.Error:
            return ErrorPage();
        default:
    }
}
